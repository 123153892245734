import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import webApiService from '@/common/service'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    attendanceWaitingApproval: {
      waiting_approval: -1,
      manual_approval: -1,
      rejected: -1
    },
    permissionWaitingApproval: {
      waiting_approval: -1,
      approved: -1,
      rejected: -1
    },
    attendanceValidation: {},
    absence: {}
  },
  mutations: {
    setAttendanceWaitingApproval(state, data) {
      state.attendanceWaitingApproval = data
    },
    setAttendanceValidation(state, data) {
      state.attendanceValidation = data
    },
    setPermissionWaitingApproval(state, data) {
      state.permissionWaitingApproval = data
    },
    setAbsence(state, data) {
      state.absence = data
    }
  },
  actions: {
    getAttendanceWaitingApproval({ commit }, { month, year, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}attendance_log/count?month=${month}&year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setAttendanceWaitingApproval', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getAttendanceValidation({ commit }, { month, year, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}attendance_log/count_waiting_approval?month=${month}&year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setAttendanceValidation', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getPermissionWaitingApproval({ commit }, { month, year, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}permission/count?month=${month}&year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setPermissionWaitingApproval', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getAbsence({ commit }, { month, year, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}attendance_log/count_absence?month=${month}&year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setAbsence', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getUndisciplinedEmployees(_, params) {
      return new Promise((resolve, reject) => {
        webApiService.get('/dashboard/undisciplined_employee', params)
        .then(response => {
          resolve(response)
        })
        .catch(reject)
      })
    },
    getAbsentEmployees(_, params) {
      return new Promise((resolve, reject) => {
        webApiService.get('/dashboard/unattendance_employee', params)
        .then(response => {
          resolve(response)
        })
        .catch(reject)
      })
    },
    getAbsentEmployeesReport(_, params) {
      return new Promise((resolve, reject) => {
        webApiService.get('/report/unattendance_employee', params)
        .then(response => {
          resolve(response)
        })
        .catch(reject)
      })
    },
  },
  getters: {
  }
})