<template>
  <div class="widget-four">

    <div class="widget-heading text-center">
      <h4 class="text-purple-custom font-weight-bold">5 Karyawan Tidak Hadir</h4>
      <p>Terbanyak Tahun Ini</p>
    </div>

    <div class="widget-content widget-content-custom">
      <div class="h-100 d-flex flex-column justify-content-between">
        <div class="d-flex flex-column justify-content-between">
          <div class="absent-employees d-flex flex-column w-100">
            <!-- <template v-if="!data">
              <div v-for="(item, index) in 5" :key="index" class="w-100 d-flex align-items-center justify-content-between">
                <div class="d-flex flex-column">
                  <PuSkeleton width="170px" />
                  <PuSkeleton width="120px" />
                </div>
    
                <PuSkeleton width="50px" />
              </div>
            </template> -->

            <!-- <template v-else>
              <div v-for="(item, index) in data" :key="index" class="w-100 d-flex align-items-center justify-content-between">
                <div class="d-flex flex-column">
                  <p class="m-0 font-weight-bold">{{ item.employeeName }}</p>
                  <p class="m-0">{{ item.employeeNumberFormatted }}</p>
                </div>
    
                <div class="text-white d-flex align-items-center justify-content-center font-weight-bold">{{ item.totalDay }}</div>
              </div>
            </template> -->

            <!-- Temporary hide section -->
            <div>
              <p class="text-center font-weight-bold">Data tidak tersedia</p>
            </div>
          </div>
        </div>

        <div class="w-100 pb-2 d-flex justify-content-center">
          <BaseButton 
            class="button--primary" 
            text="Tampilkan seluruh karyawan" 
            @button-click="openModal"
          />
        </div>
      </div>
    </div>

    <DashboardAbsentEmployeesModal />
    <div v-if="modal.backdrop" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import DashboardAbsentEmployeesModal from '@/components/dashboard/DashboardAbsentEmployeesModal.vue'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'DashboardAbsentEmployees',
  data() {
    return {
      data: null
    }
  },
  components: {
    BaseButton,
    DashboardAbsentEmployeesModal
  },
  computed: {
    ...mapState('styling', ['modal']),
  },
  created() {
    // this.absentEmployeeList()
  },
  methods: {
    ...mapActions('dashboard', ['getAbsentEmployees']),
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    openModal() {
      this.TOGGLE_MODAL()
    },
    absentEmployeeList() {
      this.getAbsentEmployees([
        {
          key: 'start_date',
          value: new Date(new Date().getFullYear(), 0, 1).toLocaleDateString('en-CA')
        },
        {
          key: 'end_date',
          value: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toLocaleDateString('en-CA')
        },
        {
          key: 'limit',
          value: 5
        },
      ])
        .then(response => {
          const resp = response.data
          this.data = resp.data
        })

    }
  }
}
</script>

<style lang="sass" scoped>
.widget-content-custom
  height: 80%

.absent-employees
  position: relative
  overflow-y: auto
  gap: 4px
  margin-bottom: 16px
  
  > div
    padding: 4px
    border-bottom: 1px solid rgba(28, 55, 90, .16)

    > div:last-child
      padding: 4px
      background: #FF0000
      border-radius: 5px
      height: 28px
</style>
