import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/admin/auth/Login.vue'
import ForgotPassword from '@/views/admin/auth/ForgotPassword.vue'
import ResetPassword from '@/views/admin/auth/ResetPassword.vue'
import UserResetPassword from '@/views/user/auth/ResetPassword.vue'
import Guide from '@/views/user/others/Guide.vue'
import LockScreen from '@/views/admin/auth/LockScreen.vue'
import Dashboard from '@/views/admin/Dashboard.vue'
import Announcement from '@/views/admin/Announcement.vue'
import Employee from '@/views/admin/Employee.vue'
import AttendanceAdjustment from '@/views/admin/adjustment/AttendanceAdjustment.vue'
import AttendanceAdjustmentEdit from '@/views/admin/adjustment/AttendanceAdjustmentEdit.vue'
import AttendanceAdjustmentList from '@/views/admin/adjustment/AttendanceAdjustmentList.vue'
import AttendanceAdjustmentDetail from '@/views/admin/adjustment/AttendanceAdjustmentDetail.vue'
import LeaveAdjustmentYearly from '@/views/admin/adjustment/LeaveAdjustmentYearly.vue'
import LeaveAdjustmentDaily from '@/views/admin/adjustment/LeaveAdjustmentDaily.vue'
import ManualAttendanceAdjustment from '@/views/admin/adjustment/ManualAttendanceAdjustment.vue'
import ManualAttendanceAdjustmentDetail from '@/views/admin/adjustment/ManualAttendanceAdjustmentDetail.vue'
import Attendance from '@/views/admin/approval/Attendance.vue'
import ApprovalPermission from '@/views/admin/approval/Permission.vue'
import AttendanceDetail from '@/views/admin/report/AttendanceDetail.vue'
import AttendanceDetailDoc from '@/views/admin/report/AttendanceDetailDoc.vue'
import AttendanceSummary from '@/views/admin/report/AttendanceSummary.vue'
import AttendanceSummaryDoc from '@/views/admin/report/AttendanceSummaryDoc.vue'
import AttendanceDetailOvertime from '@/views/admin/report/AttendanceDetailOvertime.vue'
import AttendanceDetailPermission from '@/views/admin/report/AttendanceDetailPermission.vue'
import AttendanceDetailUndisciplined from '@/views/admin/report/AttendanceDetailUndisciplined.vue'
import AbsentEmployeesDoc from '@/views/admin/report/AbsentEmployeesDoc.vue'
import Sync from '@/views/admin/sync/Sync.vue'
import EmployeeSync from '@/views/admin/sync/EmployeeSync.vue'
import AttendanceSync from '@/views/admin/sync/AttendanceSync.vue'
import AttendanceSyncDetail from '@/views/admin/sync/AttendanceSyncDetail.vue'
import AttendanceSyncList from '@/views/admin/sync/AttendanceSyncList.vue'
import AttendanceSyncValidation from '@/views/admin/sync/AttendanceSyncValidation.vue'
import ReportAttendanceAdjustment from '@/views/admin/report/AttendanceAdjustment.vue'
import AttendanceLog from '@/views/admin/report/AttendanceLog.vue'
import Shift from '@/views/admin/settings/Shift.vue'
import Holiday from '@/views/admin/settings/Holiday.vue'
import BlacklistApp from '@/views/admin/settings/BlacklistApp.vue'
import UserLevel from '@/views/admin/sysadm/UserLevel.vue'
import UserLogin from '@/views/admin/sysadm/UserLogin.vue'
import RequestNotFound from '@/views/RequestNotFound.vue'
import Credits from '@/views/admin/Credits.vue'

Vue.use(VueRouter)

  const routes = [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { 
        title: 'Datangg - Login'
      }
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
      meta: {
        title: 'Datangg - Forgot Password'
      }
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: {
        title: 'Datangg - Reset Password'
      }
    },
    {
      path: '/mobile/reset-password',
      name: 'UserResetPassword',
      component: UserResetPassword,
      meta: {
        title: 'Datangg - Reset Password'
      }
    },
    {
      path: '/panduan',
      name: 'Guide',
      component: Guide,
      meta: {
        title: 'Datangg - Panduan'
      }
    },
    {
      path: '/lock-screen',
      name: 'LockScreen',
      component: LockScreen,
      meta: {
        title: 'Datangg - Lock Screen'
      }
    },
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
      meta: { 
        title: 'Datangg - Dashboard',
        requiresAuth: true
      }
    },
    {
      path: '/announcement',
      name: 'Announcement',
      component: Announcement,
      meta: { 
        title: 'Datangg - Pengumuman',
        requiresAuth: true
      }
    },
    {
      path: '/employee',
      name: 'Employee',
      component: Employee,
      meta: { 
        title: 'Datangg - Karyawan',
        requiresAuth: true
      }
    },
    {
      path: '/sync',
      name: 'Sync',
      component: Sync,
      meta: { 
        title: 'Datangg - Sinkronisasi Kehadiran',
        requiresAuth: true
      }
    },
    {
      path: '/sync/employee',
      name: 'EmployeeSync',
      component: EmployeeSync,
      meta: { 
        title: 'Datangg - Sinkronisasi Karyawan',
        requiresAuth: true
      }
    },
    {
      path: '/sync/attendance',
      name: 'AttendanceSync',
      component: AttendanceSync,
      meta: { 
        title: 'Datangg - Sinkronisasi Penggajian',
        requiresAuth: true
      }
    },
    {
      path: '/sync/attendance/detail/:attendanceSyncId',
      name: 'AttendanceSyncDetail',
      component: AttendanceSyncDetail,
      meta: { 
        title: 'Datangg - Detail Sinkronisasi Penggajian',
        requiresAuth: true
      }
    },
    {
      path: '/sync/attendance/list',
      name: 'AttendanceSyncList',
      component: AttendanceSyncList,
      meta: { 
        title: 'Datangg - Daftar Sinkronisasi Penggajian',
        requiresAuth: true
      }
    },
    {
      path: '/sync/attendance/validation',
      name: 'AttendanceSyncValidation',
      component: AttendanceSyncValidation,
      meta: { 
        title: 'Datangg - Validasi Sinkronisasi Kehadiran',
        requiresAuth: true
      }
    },
    {
      path: '/adjustment/leave',
      name: 'LeaveAdjustmentYearly',
      component: LeaveAdjustmentYearly,
      meta: { 
        title: 'Datangg - Penyesuaian Cuti',
        requiresAuth: true
      }
    },
    {
      path: '/adjustment/leave/:leaveAdjustmentId',
      name: 'LeaveAdjustmentDaily',
      component: LeaveAdjustmentDaily,
      meta: { 
        title: 'Datangg - Penyesuaian Cuti',
        requiresAuth: true
      }
    },
    {
      path: '/adjustment/attendance',
      name: 'AttendanceAdjustment',
      component: AttendanceAdjustment,
      meta: { 
        title: 'Datangg - Penyesuaian Kehadiran',
        requiresAuth: true
      }
    },
    {
      path: '/adjustment/attendance/edit',
      name: 'AttendanceAdjustmentEdit',
      component: AttendanceAdjustmentEdit,
      meta: { 
        title: 'Datangg - Penyesuaian Kehadiran',
        requiresAuth: true
      }
    },
    {
      path: '/adjustment/attendance/list',
      name: 'AttendanceAdjustmentList',
      component: AttendanceAdjustmentList,
      meta: { 
        title: 'Datangg - Daftar Penyesuaian',
        requiresAuth: true
      }
    },
    {
      path: '/adjustment/attendance/detail/:attendanceAdjustmentId',
      name: 'AttendanceAdjustmentDetail',
      component: AttendanceAdjustmentDetail,
      meta: { 
        title: 'Datangg - Detail Penyesuaian',
        requiresAuth: true
      }
    },
    {
      path: '/adjustment/attendance/manual-upload',
      name: 'ManualAttendanceAdjustment',
      component: ManualAttendanceAdjustment,
      meta: {
        title: 'Datangg - Penyesuaian Kehadiran Manual',
        requiresAuth: true
      }
    },
    {
      path: '/adjustment/attendance/manual-upload/detail/:manualAdjustmentId',
      name: 'ManualAttendanceAdjustmentDetail',
      component: ManualAttendanceAdjustmentDetail,
      meta: {
        title: 'Datangg - Detail Penyesuaian Kehadiran Manual',
        requiresAuth: true
      }
    },
    {
      path: '/approval/attendance',
      name: 'Attendancee',
      component: Attendance,
      meta: { 
        title: 'Datangg - Persetujuan Kehadiran',
        requiresAuth: true
      }
    },
    {
      path: '/approval/permission',
      name: 'ApprovalPermission',
      component: ApprovalPermission,
      meta: { 
        title: 'Datangg - Persetujuan Izin',
        requiresAuth: true
      }
    },
    {
      path: '/report/attendance-detail',
      name: 'AttendanceDetail',
      component: AttendanceDetail,
      meta: { 
        title: 'Datangg - Detail Kehadiran',
        requiresAuth: true
      }
    },
    {
      path: '/report/attendance-detail/doc',
      name: 'AttendanceDetailDoc',
      component: AttendanceDetailDoc,
      meta: { 
        title: 'Datangg - Detail Kehadiran',
        requiresAuth: true
      }
    },
    {
      path: '/report/attendance-summary',
      name: 'AttendanceSummary',
      component: AttendanceSummary,
      meta: { 
        title: 'Datangg - Rangkuman Kehadiran',
        requiresAuth: true
      }
    },
    {
      path: '/report/attendance-summary/doc',
      name: 'AttendanceSummaryDoc',
      component: AttendanceSummaryDoc,
      meta: { 
        title: 'Datangg - Rangkuman Kehadiran',
        requiresAuth: true
      }
    },
    {
      path: '/report/attendance-detail/overtime',
      name: 'AttendanceDetailOvertime',
      component: AttendanceDetailOvertime,
      meta: { 
        title: 'Datangg - Detail Lembur',
        requiresAuth: true
      }
    },
    {
      path: '/report/attendance-detail/permission',
      name: 'AttendanceDetailPermission',
      component: AttendanceDetailPermission,
      meta: { 
        title: 'Datangg - Detail Izin',
        requiresAuth: true
      }
    },
    {
      path: '/report/attendance-detail/undisciplined',
      name: 'AttendanceDetailUndisciplined',
      component: AttendanceDetailUndisciplined,
      meta: { 
        title: 'Datangg - Detail Terlambat & Pulang Cepat',
        requiresAuth: true
      }
    },
    {
      path: '/report/attendance-log',
      name: 'AttendanceLog',
      component: AttendanceLog,
      meta: { 
        title: 'Datangg - Riwayat Kehadiran',
        requiresAuth: true
      }
    },
    {
      path: '/report/attendance-adjustment',
      name: 'ReportAttendanceAdjustment',
      component: ReportAttendanceAdjustment,
      meta: { 
        title: 'Datangg - Laporan Penyesuaian Kehadiran',
        requiresAuth: true
      }
    },
    {
      path: '/report/absent-employees/doc',
      name: 'AbsentEmployeesDoc',
      component: AbsentEmployeesDoc,
      meta: { 
        title: 'Datangg - Laporan Karyawan Tidak Hadir',
        requiresAuth: true
      }
    },
    {
      path: '/settings/shift',
      name: 'Shift',
      component: Shift,
      meta: { 
        title: 'Datangg - Shift',
        requiresAuth: true
      }
    },
    {
      path: '/settings/holiday',
      name: 'Holiday',
      component: Holiday,
      meta: { 
        title: 'Datangg - Hari Libur',
        requiresAuth: true
      }
    },
    {
      path: '/settings/blacklist-app',
      name: 'BlacklistApp',
      component: BlacklistApp,
      meta: { 
        title: 'Datangg - Blacklist App',
        requiresAuth: true
      }
    },
    {
      path: '/system-admin/user-level',
      name: 'User Level',
      component: UserLevel,
      meta: { 
        title: 'Datangg - User Level',
        requiresAuth: true
      }
    },
    {
      path: '/system-admin/user-login',
      name: 'User Login',
      component: UserLogin,
      meta: { 
        title: 'Datangg - User Login',
        requiresAuth: true
      }
    },
    {
      path: '/credits',
      name: 'Credits',
      component: Credits,
      meta: { 
        title: 'Datangg - Assets Credits',
        requiresAuth: true
      }
    },
    {
      path: '*',
      component: RequestNotFound,
      meta: {
        title: 'Datangg - Halaman tidak ditemukan',
        requiresAuth: true
      }
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if ((localStorage.getItem('token') || sessionStorage.getItem('token')) === null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => !record.meta.requiresAuth)) {
    if ((localStorage.getItem('token') || sessionStorage.getItem('token')) !== null) {
      next('/')
    } else {
      next()
    }
  } else {
    next()
  }
})

router.beforeEach(function(to, from, next) {
  let userAccess = JSON.parse(localStorage.getItem('userAccess')) || JSON.parse(sessionStorage.getItem('userAccess'))
  
  switch (to.name) {
    case 'Announcement':
      if (!userAccess.allow_announcement) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'Employee':
      if (!userAccess.allow_employee) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'LeaveAdjustmentYearly':
      if (!userAccess.allow_adjustment) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'LeaveAdjustmentDaily':
      if (!userAccess.allow_adjustment) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'AttendanceAdjustment':
      if (!userAccess.allow_attendance_adjustment) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'AttendanceAdjustmentEdit':
      if (!userAccess.allow_attendance_adjustment) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'AttendanceAdjustmentList':
      if (!userAccess.allow_attendance_adjustment) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'AttendanceAdjustmentDetail':
      if (!userAccess.allow_attendance_adjustment) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'Attendance':
      if (!userAccess.allow_approved_attendance || !userAccess.allow_rejected_attendance) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'ManualAttendanceAdjustment':
      if(!userAccess.allow_upload_manual) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'ManualAttendanceAdjustmentDetail':
      if(!userAccess.allow_upload_manual) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'ApprovalPermission':
      if (!userAccess.allow_permission) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'AttendanceDetail':
      if (!userAccess.allow_report) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'AttendanceDetailOvertime':
      if (!userAccess.allow_report) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'AttendanceDetailPermission':
      if (!userAccess.allow_report) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'AttendanceDetailUndisciplined':
      if (!userAccess.allow_report) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'AttendanceDetailDoc':
      if (!userAccess.allow_report) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'AttendanceLog':
      if (!userAccess.allow_reset_attendance) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'AttendanceSummary':
      if (!userAccess.allow_report) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'AttendanceSync':
      if (!userAccess.allow_sync_penggajian) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'AttendanceSyncDetail':
      if (!userAccess.allow_sync_penggajian) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'AttendanceSyncList':
      if (!userAccess.allow_sync_penggajian) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'AttendanceSyncValidation':
      if (!userAccess.allow_sync_penggajian) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'Holiday':
      if (!userAccess.allow_setting_holiday) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'BlacklistApp':
      if (!userAccess.allow_blacklist_app) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'Permission':
      if (!userAccess.allow_cuti_khusus && !userAccess.allow_cuti_tambahan && !userAccess.allow_system_trouble) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'Shift':
      if (!userAccess.allow_setting_shift && !userAccess.allow_setting_shift_pattern) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'UserLevel':
      if (!userAccess.allow_user_level) {
        next(from.path)
      } else {
        next()
      }
      break
    case 'UserLogin':
      if (!userAccess.allow_user_login) {
        next(from.path)
      } else {
        next()
      }
      break
    default:
      next()
  }
})

router.beforeEach((to, from, next) => {

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  next()
})

export default router
