<template>
  <div class="widget h-100 bg-light p-4">

    <div>
      <h4 class="font-weight-bold">Usia Karyawan</h4>
    </div>

    <div class="widget-content d-flex align-items-center justify-content-center" style="height: 90%;">
      <VueApexCharts 
        v-if="employeeAges" 
        type="donut" 
        width="100%" 
        height="400" 
        :options="options" 
        :series="employeeAges" 
      />
      <div v-else>
        <div>
          <PuSkeleton 
            circle 
            width="200px" 
            height="200px"
          />
        </div>
        <div class="mt-5">
          <PuSkeleton/>
          <PuSkeleton/>
          <PuSkeleton/>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import VueApexCharts from "vue-apexcharts"

export default {
  name: "DashboardChart",
  components: {
    VueApexCharts,
  },
  data() {
    return {
      options: {
        colors: ["#FF9900", "#291AD4", "#89E260"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "left",
          fontSize: "14px",
          markers: {
            width: 10,
            height: 10,
          },
          itemMargin: {
            horizontal: 30,
            vertical: 8,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
              background: "transparent",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "29px",
                  fontFamily: "Nunito, sans-serif",
                  color: undefined,
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Nunito, sans-serif",
                  color: "20",
                  offsetY: 6,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "Total",
                  color: "#888ea8",
                  fontSize: "14px",
                  fontFamily: "Nunito, sans-serif",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce(function (a, b) {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
        labels: ["Dibawah 20 tahun", "Antara 20 - 30 tahun", "Diatas 30 tahun"],
        responsive: [
          {
            breakpoint: 1439,
            options: {
              chart: {
                width: "250px",
                height: "390px",
              },
              legend: {
                position: "bottom",
              },
              plotOptions: {
                pie: {
                  donut: {
                    size: "65%",
                  },
                },
              },
            },
          },
        ],
      }
    };
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('baseComponents', ['employeeAges'])
  },
  created() {
    this.getEmployeeAges(this.authorization)
  },
  methods: {
    ...mapActions('baseComponents', ['getEmployeeAges'])
  }
};
</script>

<style lang="sass" scoped>
.bg-light
  background-color: #FFFFFF !important
</style>
