<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="true"
    :smallModal="true"
    @close-modal="closeModal"
  >

    <template #body>

      <!-- Add modal -->
      <div>
        <div class="modal-main__top mb-2">
          <svg
            class="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            style="width:24px;height:24px" 
            viewBox="0 0 16 16"
          >
            <path fill="none" stroke="#2196F3" stroke-linejoin="round" d="M7.563 1.545H2.5v10.91h9V5.364M7.563 1.545L11.5 5.364M7.563 1.545v3.819H11.5m-7 9.136h9v-7M4 7.5h6M4 5h2m-2 5h6"/>
          </svg>
          <span class="font-weight-bold">Laporan Karyawan Tidak Hadir</span>
        </div>

        <div class="modal-main__middle my-3">
          <div>
            <div>
              <label>Periode</label>
              <date-picker 
                :value="monthsDateRange"
                @input="handleDateRangeFormatting"
                class="w-100"
                format="YYYY-MM-DD"
                type="month"
                range
                range-separator=" sampai "
                value-type="format"
                :disabled-date="oneYearRange"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Add modal -->

    </template>

    <template #footer>

      <!-- Add modal -->
      <div>
        <BaseButton
          class="button--cancel mr-2"
          text="Batal"
          @button-click="closeModal"
        />
        <BaseButton
          class="button--primary"
          text="Tampilkan"
          @button-click="showReportData"
        />
      </div>
      <!-- Add modal -->

    </template>

  </BaseModal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import BaseModal from '@/components/base/BaseModal'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'DashboardAbsentEmployeesModal',
  components: {
    BaseModal,
    BaseButton
  },
  data() {
    return {
      monthsDateRange: [new Date(new Date().getFullYear(), 0, 1).toLocaleDateString('en-CA'), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toLocaleDateString('en-CA')],
    }
  },
  computed: {
    ...mapState('styling', ['modal']),
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    closeModal() {
      this.TOGGLE_MODAL()
    },
    handleDateRangeFormatting(date) {
      if (date && date.length === 2) {
        const splitEndDate = date[1].split('-')
        const endDateWithLastDay = new Date(Number(splitEndDate[0]), Number(splitEndDate[1]), 0)
        const formattedYear = endDateWithLastDay.getFullYear()
        const formattedMonth = ("0" + (endDateWithLastDay.getMonth() + 1)).slice(-2)
        const formattedDay = ("0" + endDateWithLastDay.getDate()).slice(-2)
        const formattedEndDate = `${formattedYear}-${formattedMonth}-${formattedDay}`
        
        this.monthsDateRange = [
          date[0],
          formattedEndDate
        ]
      }      
    },
    oneYearRange(date, currentValues) {
      if (!currentValues.length || currentValues.length === 2) {
        return date > new Date()
      } else if (currentValues.length === 1) {
        return (date.getTime() - currentValues[0].getTime() > 365 * 24 * 3600 * 1000) || (date.getTime() + 365 * 24 * 3600 * 1000 < currentValues[0].getTime())
      } 
      return false
    },
    showReportData() {
      this.$router.push(`/report/absent-employees/doc?s=${this.monthsDateRange[0]}&e=${this.monthsDateRange[1]}`)
    }
  },
}
</script>

<style lang="sass" scoped>
label
  color: #000000 !important

.custom
  
  &-input
    padding: 1px 5px
    border: 1px solid #CCCCCC
    border-radius: 5px

    &[type=text], &[type=number]
      height: 34.5px

    &[disabled]
      color: #4d4d4d

    &--error
      border: 1px solid #EE2A2A

    &:hover
      border-color: #409AFF

    &:focus
      border-color: #409AFF
  
.v-select::v-deep .vs__dropdown-toggle
  border-radius: 5px  

.dropbox
  padding: 1px 5px
  border: 1px solid #CCCCCC
  border-radius: 5px

  &:hover
    border-color: #409AFF

.file-container
  padding: 1px 5px
  border: 1px solid #CCCCCC
  border-radius: 5px

  &__clear
    background: none
    border: none

.input-file
  opacity: 0
  width: 100%
  height: 34.5px
  position: absolute
  cursor: pointer

.img-thumbnail
  height: 100px
  width: 100px
  object-fit: cover

.cursor-pointer
  cursor: pointer
</style>