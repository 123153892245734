<template>
  <div class="widget-four">

    <div class="widget-heading d-flex flex-column flex-sm-row align-items-center justify-content-center" style="gap: 24px;">
      <img src="@/assets/img/undisciplined-warning.png" alt="Warning" width="80px">

      <div class="d-flex flex-column align-items-center justify-content-center">
        <h4 class="text-purple-custom font-weight-bold">Karyawan Tidak Displin - Bulan ini</h4>
        <div class="undisciplined-tabs">
          <div
            class="undisciplined-tabs__item"
            :class="selectedTab === 'terlambat' ? ' undisciplined-tabs__item--selected' : ''"
            @click="undisciplinedEmployeesList('terlambat')"
          >
            <p>Terlambat</p>
            <p v-if="totalLate !== -1">{{ totalLate }}</p>
            <PuSkeleton v-else width="25px" />
          </div>
          <div
            class="undisciplined-tabs__item"
            :class="selectedTab === 'pulang_cepat' ? ' undisciplined-tabs__item--selected' : ''"
            @click="undisciplinedEmployeesList('pulang_cepat')"
          >
            <p>Pulang Cepat</p>
            <p v-if="totalEarlyOut !== -1">{{ totalEarlyOut }}</p>
            <PuSkeleton v-else width="25px" />
          </div>
        </div>
      </div>
    </div>

    <div class="widget-content">
      <div class="d-flex align-items-center" style="gap: 8px;">
        <img src="@/assets/img/late-early-out.png" alt="Warning" width="32px">
        <h5 class="m-0 font-weight-bold">{{ selectedTab === 'terlambat' ? 'Terlambat' : 'Pulang Cepat'}}</h5>
      </div>

      <div class="table-responsive mt-3">
        <table class="table w-100">
          <thead>
            <tr>
              <th>ID Karyawan</th>
              <th>Nama</th>
              <th>Hari</th>
              <th>Total Waktu</th>
            </tr>
          </thead>
          <tbody v-if="!data">
            <tr v-for="(item, index) in 10" :key="index">
              <td>
                <PuSkeleton width="125px" />
              </td>
              <td>
                <PuSkeleton width="125px" />
              </td>
              <td>
                <PuSkeleton width="50px" />
              </td>
              <td>
                <PuSkeleton width="125px" />
              </td>
            </tr>
          </tbody>
          <tbody v-if="data && data.length">
            <tr v-for="(item, index) in data" :key="index">
              <td>{{ item.employeeNumberFormatted }}</td>
              <td>{{ item.employeeName }}</td>
              <td>{{ item.totalDays }}</td>
              <td>{{ item.totalHoursMinutes }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p>
        Data yang ditampilkan hanya 10 karyawan dengan jumlah hari terbanyak, untuk selengkapnya silahkan cek pada
        <router-link to="/report/attendance-detail/undisciplined">
          laporan detail tidak displin
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'DashboardUndisciplinedEmployees',
  data() {
    return {
      selectedTab: 'terlambat',
      totalLate: -1,
      totalEarlyOut: -1,
      data: null
    }
  },
  created() {
    this.undisciplinedEmployeesList()
  },
  methods: {
    ...mapActions('dashboard', ['getUndisciplinedEmployees']),
    undisciplinedEmployeesList(type) {
      let dataType
      if (!type) {
        dataType = this.selectedTab
      } else {
        dataType = type
        this.selectedTab = type
      }


      this.getUndisciplinedEmployees([
        {
          key: 'type',
          value: dataType
        },
        {
          key: 'start_date',
          value: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString('en-CA')
        },
        {
          key: 'end_date',
          value: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toLocaleDateString('en-CA')
        },
        {
          key: 'limit',
          value: 10
        },
      ])
        .then(response => {
          const resp = response.data.data
          this.totalLate = resp.totalTerlambat
          this.totalEarlyOut = resp.totalPulangCepat
          this.data = resp.list
        })

    }
  }
}
</script>

<style lang="sass" scoped>
.undisciplined-tabs
  display: flex
  align-items: center
  justify-content: center
  gap: 10px

  &__item
    padding: 4px 8px
    display: flex
    align-items: center
    justify-content: space-between
    gap: 12px
    min-width: 124px
    background: #E94444
    border-radius: 5px
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)
    cursor: pointer

    p
      margin: 0
      color: #ffffff
      font-weight: bold

    &--selected
      background: #b93a3d
</style>
