<template>
  <div class="widget widget-five custom-widget">
    <div class="widget-content h-100">
      <div class="header custom-header--main">
        <div class="header-body w-100 d-flex align-items-end justify-content-between">
          <h5 class="mb-1 mt-3 text-white">
            Persetujuan
          </h5>
          <p class="meta-date text-white">
            Bulan ini
          </p>
        </div>
      </div>

      <div class="w-content row align-items-center" style="gap: 24px; height: 80%;">
        <div class="col">
          <div class="approval mx-auto d-flex flex-column align-items-start" style="max-width: 360px;">
            <div class="approval__header w-100 d-flex align-items-center justify-content-between">
              <h6 class="font-weight-bold text-black">Kehadiran</h6>
              <router-link to="/approval/attendance">
                <span>Buka</span>

                <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13 5H4C3.72 5 3.5 4.78 3.5 4.5C3.5 4.22 3.72 4 4 4H13C13.28 4 13.5 4.22 13.5 4.5C13.5 4.78 13.28 5 13 5Z"
                    fill="white" />
                  <path
                    d="M10.5 8.5C10.4344 8.5008 10.3694 8.48786 10.3091 8.46203C10.2489 8.4362 10.1946 8.39803 10.15 8.35C9.95 8.15 9.95 7.84 10.15 7.64L13.3 4.49L10.15 1.34C9.95 1.14 9.95 0.829996 10.15 0.629996C10.35 0.429996 10.66 0.429996 10.86 0.629996L14.36 4.13C14.56 4.33 14.56 4.64 14.36 4.84L10.86 8.34C10.76 8.44 10.63 8.49 10.51 8.49L10.5 8.5Z"
                    fill="white" />
                </svg>
              </router-link>
            </div>

            <div class="approval__detail-items w-100 d-flex flex-column" style="gap: 16px;">
              <div class="w-100 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.5418 16.2557L10.8844 3.89244C10.4125 3.01588 9.15545 3.01588 8.68319 3.89244L2.02616 16.2557C1.9237 16.446 1.87233 16.6596 1.87707 16.8757C1.88181 17.0918 1.94248 17.3029 2.05318 17.4885C2.16388 17.6742 2.32081 17.8279 2.50866 17.9348C2.69651 18.0416 2.90886 18.098 3.12498 18.0983H16.441C16.6573 18.0983 16.8699 18.0422 17.058 17.9355C17.2461 17.8288 17.4033 17.6751 17.5143 17.4894C17.6252 17.3037 17.686 17.0924 17.6909 16.8762C17.6957 16.6599 17.6443 16.4462 17.5418 16.2557ZM9.78397 16.1842C9.62945 16.1842 9.47841 16.1384 9.34993 16.0526C9.22145 15.9667 9.12132 15.8447 9.06219 15.702C9.00306 15.5592 8.98759 15.4021 9.01773 15.2506C9.04788 15.099 9.12228 14.9598 9.23154 14.8506C9.3408 14.7413 9.48001 14.6669 9.63155 14.6367C9.7831 14.6066 9.94019 14.6221 10.0829 14.6812C10.2257 14.7403 10.3477 14.8405 10.4336 14.9689C10.5194 15.0974 10.5652 15.2485 10.5652 15.403C10.5652 15.6102 10.4829 15.8089 10.3364 15.9554C10.1899 16.1019 9.99117 16.1842 9.78397 16.1842ZM10.6324 8.32681L10.4082 13.0924C10.4082 13.2582 10.3423 13.4172 10.2251 13.5344C10.1079 13.6516 9.94895 13.7174 9.78319 13.7174C9.61743 13.7174 9.45846 13.6516 9.34125 13.5344C9.22404 13.4172 9.15819 13.2582 9.15819 13.0924L8.93397 8.32877C8.92893 8.21493 8.94686 8.10125 8.98669 7.9945C9.02652 7.88774 9.08744 7.7901 9.16581 7.70739C9.24419 7.62468 9.33841 7.55859 9.44287 7.51307C9.54732 7.46756 9.65987 7.44353 9.77381 7.44244H9.78202C9.89673 7.44238 10.0103 7.46556 10.1158 7.51059C10.2213 7.55561 10.3166 7.62155 10.3959 7.70442C10.4752 7.78728 10.5369 7.88537 10.5773 7.99274C10.6177 8.10011 10.6359 8.21455 10.6308 8.32916L10.6324 8.32681Z"
                        fill="#FF9900" />
                    </svg>
                  </div>
                  <p class="mb-0 font-weight-bold">Menunggu Persetujuan</p>
                </div>
                <p v-if="attendanceWaitingApproval !== -1" class="text-black font-weight-bold m-0">{{
                  attendanceWaitingApproval }}</p>
                <PuSkeleton v-else width="50px" />
              </div>
              <div class="w-100 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div>
                    <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_8_132)">
                        <rect x="4" y="3.33333" width="19" height="19" rx="9.5" fill="#009918" />
                        <g clip-path="url(#clip0_8_132)">
                          <path
                            d="M9.41666 14.5L11.3026 15.9146C11.4229 16.0048 11.5735 16.0451 11.7229 16.0269C11.8723 16.0088 12.0088 15.9336 12.1041 15.8172L17 9.83333"
                            stroke="white" stroke-width="2" stroke-linecap="round" />
                        </g>
                      </g>
                      <defs>
                        <filter id="filter0_d_8_132" x="0" y="0.333328" width="27" height="27"
                          filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="1" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8_132" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8_132" result="shape" />
                        </filter>
                        <clipPath id="clip0_8_132">
                          <rect width="14" height="14" fill="white" transform="translate(6.5 5.83333)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p class="mb-0 font-weight-bold">Disetujui</p>
                </div>
                <p v-if="attendanceApproved !== -1" class="text-black font-weight-bold m-0">{{ attendanceApproved }}</p>
                <PuSkeleton v-else width="50px" />
              </div>
              <div class="w-100 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div>
                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_8_142)">
                        <rect x="4" y="3" width="19" height="19" rx="9.5" fill="#FF0000" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M13.5 13.7378L16.5934 16.8312C16.7576 16.9954 16.9802 17.0876 17.2123 17.0876C17.4445 17.0876 17.6671 16.9954 17.8312 16.8312C17.9954 16.6671 18.0876 16.4445 18.0876 16.2123C18.0876 15.9802 17.9954 15.7576 17.8312 15.5934L14.7367 12.5L17.8307 9.40658C17.9119 9.32531 17.9763 9.22882 18.0203 9.12264C18.0642 9.01646 18.0868 8.90267 18.0868 8.78775C18.0868 8.67284 18.0641 8.55905 18.0201 8.45289C17.9761 8.34673 17.9116 8.25028 17.8304 8.16904C17.7491 8.0878 17.6526 8.02337 17.5464 7.97942C17.4402 7.93546 17.3265 7.91286 17.2115 7.91288C17.0966 7.91291 16.9828 7.93557 16.8767 7.97957C16.7705 8.02358 16.6741 8.08806 16.5928 8.16933L13.5 11.2627L10.4066 8.16933C10.3259 8.08572 10.2294 8.01902 10.1226 7.97311C10.0159 7.92721 9.90111 7.90301 9.78493 7.90195C9.66875 7.90089 9.55352 7.92297 9.44596 7.96692C9.33841 8.01086 9.24068 8.07579 9.15849 8.1579C9.0763 8.24002 9.01128 8.33768 8.96723 8.4452C8.92319 8.55271 8.90099 8.66792 8.90195 8.7841C8.9029 8.90028 8.92698 9.01511 8.97279 9.12189C9.0186 9.22866 9.08521 9.32524 9.16874 9.406L12.2633 12.5L9.16933 15.594C9.08579 15.6748 9.01918 15.7713 8.97337 15.8781C8.92757 15.9849 8.90348 16.0997 8.90253 16.2159C8.90157 16.3321 8.92377 16.4473 8.96781 16.5548C9.01186 16.6623 9.07688 16.76 9.15907 16.8421C9.24127 16.9242 9.33899 16.9891 9.44655 17.0331C9.5541 17.077 9.66933 17.0991 9.78551 17.0981C9.90169 17.097 10.0165 17.0728 10.1232 17.0269C10.23 16.981 10.3265 16.9143 10.4072 16.8307L13.5 13.7378Z"
                          fill="white" />
                      </g>
                      <defs>
                        <filter id="filter0_d_8_142" x="0" y="0" width="27" height="27" filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="1" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8_142" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8_142" result="shape" />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                  <p class="mb-0 font-weight-bold">Ditolak</p>
                </div>
                <p v-if="attendanceRejected !== -1" class="text-black font-weight-bold m-0">{{ attendanceRejected }}</p>
                <PuSkeleton v-else width="50px" />
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="approval mx-auto d-flex flex-column align-items-start" style="max-width: 360px;">
            <div class="approval__header w-100 d-flex align-items-center justify-content-between">
              <h6 class="font-weight-bold text-black">Izin</h6>
              <router-link to="/approval/permission">
                <span>Buka</span>

                <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13 5H4C3.72 5 3.5 4.78 3.5 4.5C3.5 4.22 3.72 4 4 4H13C13.28 4 13.5 4.22 13.5 4.5C13.5 4.78 13.28 5 13 5Z"
                    fill="white" />
                  <path
                    d="M10.5 8.5C10.4344 8.5008 10.3694 8.48786 10.3091 8.46203C10.2489 8.4362 10.1946 8.39803 10.15 8.35C9.95 8.15 9.95 7.84 10.15 7.64L13.3 4.49L10.15 1.34C9.95 1.14 9.95 0.829996 10.15 0.629996C10.35 0.429996 10.66 0.429996 10.86 0.629996L14.36 4.13C14.56 4.33 14.56 4.64 14.36 4.84L10.86 8.34C10.76 8.44 10.63 8.49 10.51 8.49L10.5 8.5Z"
                    fill="white" />
                </svg>
              </router-link>
            </div>

            <div class="approval__detail-items w-100 d-flex flex-column" style="gap: 16px;">
              <div class="w-100 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.5418 16.2557L10.8844 3.89244C10.4125 3.01588 9.15545 3.01588 8.68319 3.89244L2.02616 16.2557C1.9237 16.446 1.87233 16.6596 1.87707 16.8757C1.88181 17.0918 1.94248 17.3029 2.05318 17.4885C2.16388 17.6742 2.32081 17.8279 2.50866 17.9348C2.69651 18.0416 2.90886 18.098 3.12498 18.0983H16.441C16.6573 18.0983 16.8699 18.0422 17.058 17.9355C17.2461 17.8288 17.4033 17.6751 17.5143 17.4894C17.6252 17.3037 17.686 17.0924 17.6909 16.8762C17.6957 16.6599 17.6443 16.4462 17.5418 16.2557ZM9.78397 16.1842C9.62945 16.1842 9.47841 16.1384 9.34993 16.0526C9.22145 15.9667 9.12132 15.8447 9.06219 15.702C9.00306 15.5592 8.98759 15.4021 9.01773 15.2506C9.04788 15.099 9.12228 14.9598 9.23154 14.8506C9.3408 14.7413 9.48001 14.6669 9.63155 14.6367C9.7831 14.6066 9.94019 14.6221 10.0829 14.6812C10.2257 14.7403 10.3477 14.8405 10.4336 14.9689C10.5194 15.0974 10.5652 15.2485 10.5652 15.403C10.5652 15.6102 10.4829 15.8089 10.3364 15.9554C10.1899 16.1019 9.99117 16.1842 9.78397 16.1842ZM10.6324 8.32681L10.4082 13.0924C10.4082 13.2582 10.3423 13.4172 10.2251 13.5344C10.1079 13.6516 9.94895 13.7174 9.78319 13.7174C9.61743 13.7174 9.45846 13.6516 9.34125 13.5344C9.22404 13.4172 9.15819 13.2582 9.15819 13.0924L8.93397 8.32877C8.92893 8.21493 8.94686 8.10125 8.98669 7.9945C9.02652 7.88774 9.08744 7.7901 9.16581 7.70739C9.24419 7.62468 9.33841 7.55859 9.44287 7.51307C9.54732 7.46756 9.65987 7.44353 9.77381 7.44244H9.78202C9.89673 7.44238 10.0103 7.46556 10.1158 7.51059C10.2213 7.55561 10.3166 7.62155 10.3959 7.70442C10.4752 7.78728 10.5369 7.88537 10.5773 7.99274C10.6177 8.10011 10.6359 8.21455 10.6308 8.32916L10.6324 8.32681Z"
                        fill="#FF9900" />
                    </svg>
                  </div>
                  <p class="mb-0 font-weight-bold">Menunggu Persetujuan</p>
                </div>
                <p v-if="permissionWaitingApproval !== -1" class="text-black font-weight-bold m-0">{{
                  permissionWaitingApproval }}</p>
                <PuSkeleton v-else width="50px" />
              </div>
              <div class="w-100 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div>
                    <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_8_132)">
                        <rect x="4" y="3.33333" width="19" height="19" rx="9.5" fill="#009918" />
                        <g clip-path="url(#clip0_8_132)">
                          <path
                            d="M9.41666 14.5L11.3026 15.9146C11.4229 16.0048 11.5735 16.0451 11.7229 16.0269C11.8723 16.0088 12.0088 15.9336 12.1041 15.8172L17 9.83333"
                            stroke="white" stroke-width="2" stroke-linecap="round" />
                        </g>
                      </g>
                      <defs>
                        <filter id="filter0_d_8_132" x="0" y="0.333328" width="27" height="27"
                          filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="1" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8_132" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8_132" result="shape" />
                        </filter>
                        <clipPath id="clip0_8_132">
                          <rect width="14" height="14" fill="white" transform="translate(6.5 5.83333)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p class="mb-0 font-weight-bold">Disetujui</p>
                </div>
                <p v-if="permissionApproved !== -1" class="text-black font-weight-bold m-0">{{ permissionApproved }}</p>
                <PuSkeleton v-else width="50px" />
              </div>
              <div class="w-100 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div>
                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_8_142)">
                        <rect x="4" y="3" width="19" height="19" rx="9.5" fill="#FF0000" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M13.5 13.7378L16.5934 16.8312C16.7576 16.9954 16.9802 17.0876 17.2123 17.0876C17.4445 17.0876 17.6671 16.9954 17.8312 16.8312C17.9954 16.6671 18.0876 16.4445 18.0876 16.2123C18.0876 15.9802 17.9954 15.7576 17.8312 15.5934L14.7367 12.5L17.8307 9.40658C17.9119 9.32531 17.9763 9.22882 18.0203 9.12264C18.0642 9.01646 18.0868 8.90267 18.0868 8.78775C18.0868 8.67284 18.0641 8.55905 18.0201 8.45289C17.9761 8.34673 17.9116 8.25028 17.8304 8.16904C17.7491 8.0878 17.6526 8.02337 17.5464 7.97942C17.4402 7.93546 17.3265 7.91286 17.2115 7.91288C17.0966 7.91291 16.9828 7.93557 16.8767 7.97957C16.7705 8.02358 16.6741 8.08806 16.5928 8.16933L13.5 11.2627L10.4066 8.16933C10.3259 8.08572 10.2294 8.01902 10.1226 7.97311C10.0159 7.92721 9.90111 7.90301 9.78493 7.90195C9.66875 7.90089 9.55352 7.92297 9.44596 7.96692C9.33841 8.01086 9.24068 8.07579 9.15849 8.1579C9.0763 8.24002 9.01128 8.33768 8.96723 8.4452C8.92319 8.55271 8.90099 8.66792 8.90195 8.7841C8.9029 8.90028 8.92698 9.01511 8.97279 9.12189C9.0186 9.22866 9.08521 9.32524 9.16874 9.406L12.2633 12.5L9.16933 15.594C9.08579 15.6748 9.01918 15.7713 8.97337 15.8781C8.92757 15.9849 8.90348 16.0997 8.90253 16.2159C8.90157 16.3321 8.92377 16.4473 8.96781 16.5548C9.01186 16.6623 9.07688 16.76 9.15907 16.8421C9.24127 16.9242 9.33899 16.9891 9.44655 17.0331C9.5541 17.077 9.66933 17.0991 9.78551 17.0981C9.90169 17.097 10.0165 17.0728 10.1232 17.0269C10.23 16.981 10.3265 16.9143 10.4072 16.8307L13.5 13.7378Z"
                          fill="white" />
                      </g>
                      <defs>
                        <filter id="filter0_d_8_142" x="0" y="0" width="27" height="27" filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="1" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8_142" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8_142" result="shape" />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                  <p class="mb-0 font-weight-bold">Ditolak</p>
                </div>
                <p v-if="permissionRejected !== -1" class="text-black font-weight-bold m-0">{{ permissionRejected }}</p>
                <PuSkeleton v-else width="50px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardAttendanceApproval',
  props: {
    attendanceWaitingApproval: Number,
    attendanceApproved: Number,
    attendanceRejected: Number,
    permissionWaitingApproval: Number,
    permissionApproved: Number,
    permissionRejected: Number,
  }
}
</script>

<style lang="sass" scoped>
.custom-header--main
  border-radius: 6px 6px 0 0
  padding-left: 20px !important
  padding-right: 20px !important
  background-color: #291AD4

.custom-widget
  padding: 0
  border-radius: 6px

.task-left
  height: 100px !important
  width: 100px !important
  padding: 25px !important

  &--custom
    background-color: #4A9B93 !important

.widget-five

  .widget-content

    .header
      border-bottom: none !important

.approval

  &__header
    margin-bottom: 24px

    a
      color: #FFFFFF
      background: #000000
      padding: 5px 12px
      border-radius: 9999px
      font-size: 12px
      cursor: pointer

  &__detail-items
    > div 
      padding: 4px
      border-bottom: 1px solid rgba(28, 55, 90, .16)

      svg 
        width: 20px
        height: 20px

      div p 
        margin-left: 16px

      &:nth-of-type(1) div p
        color: #FF9900

      &:nth-of-type(2) div p
        color: #009918

      &:nth-of-type(3) div p
        color: #FF0000
</style>