<template>
  <div>
    <BaseHeader />
    <BaseSidebar>
      <div class="layout-px-spacing">
        <div class="grid-container">
          <div class="item-1">
            <BaseBirthDate />
          </div>

          <div class="item-2">
            <DashboardAttendanceApproval
              :attendanceWaitingApproval="Number(attendanceWaitingApproval.waiting_approval)"
              :attendanceApproved="Number(attendanceWaitingApproval.manual_approval)"
              :attendanceRejected="Number(attendanceWaitingApproval.rejected)"
              :permissionWaitingApproval="Number(permissionWaitingApproval.waiting_approval)"
              :permissionApproved="Number(permissionWaitingApproval.approved)"
              :permissionRejected="Number(permissionWaitingApproval.rejected)"
            />
          </div>

          <div class="item-3">
            <BaseChart />
          </div>

          <div class="item-4">
            <DashboardUndisciplinedEmployees />
          </div>

          <div class="item-5">
            <img src="@/assets/img/bpp/bdkr-nkri.png" alt="Logo Berdikari">
          </div>

          <div class="item-6">
            <DashboardAbsentEmployees />
          </div>
        </div>
      </div>
    </BaseSidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader.vue'
import BaseSidebar from '@/components/base/BaseSidebar.vue'
import BaseChart from '@/components/base/BaseChart.vue'
import BaseBirthDate from '@/components/base/BaseBirthDate.vue'
import DashboardAttendanceApproval from '@/components/dashboard/DashboardAttendanceApproval.vue'
import DashboardAbsentEmployees from '@/components/dashboard/DashboardAbsentEmployees.vue'
import DashboardUndisciplinedEmployees from '@/components/dashboard/DashboardUndisciplinedEmployees.vue'

export default {
  name: 'Dashboard',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseChart,
    BaseBirthDate,
    DashboardAttendanceApproval,
    DashboardAbsentEmployees,
    DashboardUndisciplinedEmployees
  },
  props: {
    customStyleText: String,
    customStyleBg: String
  },
  data() {
    return {
      waitingApprovalStatus: {},
      attendanceValidationStatus: {}
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('dashboard', ['attendanceWaitingApproval', 'permissionWaitingApproval', 'attendanceValidation']),
    ...mapState('global', ['lateIn', 'earlyOut'])
  },
  created() {
    this.getAttendanceWaitingApproval({
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      token: this.authorization
    })
    this.getPermissionWaitingApproval({
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      token: this.authorization
    })
    this.loadAttendanceValidation(new Date().getMonth() + 1)
    this.loadAbsence(new Date().getMonth() + 1)
    this.loadLateIn(new Date().getMonth() + 1)
    this.loadEarlyOut(new Date().getMonth() + 1)
  },
  methods: {
    ...mapActions('dashboard', ['getAttendanceWaitingApproval', 'getPermissionWaitingApproval', 'getAttendanceValidation', 'getAbsence']),
    ...mapActions('global', ['getLateIn', 'getEarlyOut']),
    ...mapMutations('dashboard', ['setAttendanceValidation', 'setAbsence']),
    ...mapMutations('global', ['setLateIn', 'setEarlyOut']),
    loadAttendanceValidation(month) {
      this.setAttendanceValidation({})
      let year = new Date().getFullYear()
      this.getAttendanceValidation({
        month: month,
        year: year,
        token: this.authorization
      })
    },
    loadAbsence(month) {
      this.setAbsence({})
      let year = new Date().getFullYear()
      this.getAbsence({
        month: month,
        year: year,
        token: this.authorization
      })
    },
    loadLateIn(month) {
      this.setLateIn(null)
      let year = new Date().getFullYear()
      this.getLateIn({
        month: month,
        year: year,
        token: this.authorization
      })
    },
    loadEarlyOut(month) {
      this.setEarlyOut(null)
      let year = new Date().getFullYear()
      this.getEarlyOut({
        month: month,
        year: year,
        token: this.authorization
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.grid-container
  margin-top: 25px
  padding-bottom: 24px
  display: grid
  grid-template-columns: repeat(12, 1fr)
  grid-template-rows: repeat(7, minmax(0, 1fr))
  gap: 1rem

.item-1
  grid-column: span 4
  grid-row: span 4

.item-2
  grid-column: 5 / span 8
  grid-row: span 2

.item-3
  grid-column: 1 / span 4
  grid-row: 5 / span 3

.item-4
  grid-column: 5 / span 5
  grid-row: 3 / span 5

.item-5
  grid-column: 10 / span 3
  grid-row: 3 / span 1
  align-self: center
  justify-self: center

  img
    width: 100%
    height: 100px
    object-fit: cover

.item-6
  grid-column: 10 / span 3
  grid-row: 4 / span 4

@media (max-width: 768px)
  .grid-container
    grid-template-columns: repeat(1, minmax(0, 1fr))
    grid-template-rows: none

    .item-1, .item-2, .item-3, .item-4, .item-5, .item-6
      grid-column: span 1 / span 1
      grid-row: auto
</style>
